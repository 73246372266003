import React from 'react';
import fresno from '../assets/Logos/fresno.png';
import lbusd from '../assets/Logos/lbusd.jpg'
import {Link} from "react-router-dom";
import '../styles.css'

export const Header = ({}) => (
    <div className={'header is-mobile'} style={{marginBottom: "-30px"}}>
        <div className={'column is-narrow header-mobile'}>
            {/*<img src={fresno}/>*/}
            {/*<p className={'header-mobile-p'}>*/}
            {/*  Presented by CORE Districts.*/}
            {/*</p>*/}
        </div>
            <div className={'column is-narrow has-text-centered'}>
                <Link to={`/`}>
                <p className={'header-title'}>
                    College Roadmap
                </p>
                </Link>
            </div>
    </div>
)
