import React from 'react';
import  Main from "./Main";
import { withRouter, Router } from "react-router";
import createHistory from 'history/createBrowserHistory';
import { Header } from './Header'
import { Footer } from "./Footer";

const history = createHistory();

const Dashboard = () => (
    <Router history={history}>
            <Header/>
            <Main/>
            <Footer/>
    </Router>
)

export default Dashboard