import React from 'react';
import coreLogo from '../assets/Logos/core.png'
import '../styles.css'

export const Footer = ({}) => {
    const list = [
        '@2019 Core Districts',
        '1107 9th Street, Suite 500, Sacramento, CA 95814',
        '(916)596-2548,'
    ];

    
    return (
        <div className={'footer'}>
            <div className={'column is-3'}>
                <img style={{display:'block', margin:'auto'}} src={coreLogo}/>
            </div>
            <div className={'column is-9'}>
                <ul>
                    {list.map((item,index) => {
                        return (
                            index ? <li>
                                        <b>{item}</b>
                                    </li>
                                :   <li>
                                        <b>{item}</b>
                                    </li>
                            )}
                        )
                    }
                  <li>
                      <strong>
                          <a href="mailto:support@coredistricts.org ">support@coredistricts.org</a>
                      </strong>
                  </li>
                </ul>
            </div>
        </div>
    )
}

// &bull;.

