import React from "react";
import { Route } from "react-router-dom";
import { withRouter } from "react-router-dom";
import { Lane } from "../Lanes/Lane";
import '../styles.css';
import {LandingPage} from "./LandingPage"


const Main = props => {
    return (
        <div>
            <Route exact path={'/'} component={LandingPage}/>
            <Route exact path={'/lane/:laneNumber'} component={Lane}/>
        </div>
    )
};

export default withRouter(Main);