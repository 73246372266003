import React from 'react';
import { getLaneInfo } from "./constants";
import {Link} from 'react-router-dom'
import arrow from './Arrow.png';
import '../styles.css'


export const Lane = props => {
    const {
        match: {
            params: {laneNumber}
        }
    } = props;
    const intLane = parseInt(laneNumber);
    const {logo, title, list, subtitle } = getLaneInfo(intLane);
    const prev = intLane === 1 ? 5 : intLane-1;
    const next  = intLane === 5 ? 1 : intLane+1;
    return (
        <div className={'laneWrapper'}>
            <div className={'columns'} style={{minHeight:'60vh'}}>
                <div className={'column  is-hidden-mobile'}>
                    {intLane === 1
                        ? null
                        : <Link to={`/lane/${prev}`}>
                            <button className={'navSidebar'}>
                                <img src={arrow} className={'lane-arrow'} style={{transform: 'rotate(180deg)' }}/>
                            </button>
                        </Link>
                    }
                </div>

                <div className={'is-hidden-tablet'}>
                  <p className={'lane-font-title-mobile'}>
                    LANE {laneNumber}
                  </p>
                  <p className={'lane-font-subtitle-mobile'}>
                    {title}
                  </p>
                </div>

                <div className={'column is-4 is-hidden-mobile'}>
                    <img className={'lane-logo'} src={logo}/>
                </div>


              <div className={'columns is-hidden-tablet is-mobile'}>
                <div className={'column is-2'}>
                    {intLane === 1
                      ? null
                      : <Link to={`/lane/${prev}`}>
                        <button className={'navSidebar'}>
                            <img src={arrow} className={'lane-arrow'} style={{transform:'rotate(180deg)' }}/>
                        </button>
                      </Link>
                  }
                </div>

                <div className={'column is-7'}>
                  <img className={'lane-logo'} src={logo}/>
                </div>

                <div className={'column is-2'}>
                  {intLane === 5
                    ? null
                    : <Link to={`/lane/${next}`}>
                      <button className={'navSidebar'}>
                          <img src={arrow} className={'lane-arrow'}/>
                      </button>
                    </Link>
                }
                </div>
              </div>





                <div className={'column is-5'}>
                    <div>
                      <div className={'is-hidden-mobile'}>
                        <p className={'lane-font-title'}>
                            LANE {laneNumber}
                        </p>
                        <p className={'lane-font-subtitle-header'}>
                            {title}
                        </p>
                        <p className={'lane-font-subtitle-subheader'}>
                          {subtitle}
                        </p>
                      </div>


                        {list.map(li =>
                            <div>
                                <p className={'lane-font-subtitle-subheader'}>
                                  {li.title}
                                </p>
                                <p className={'lane-font-body'}>
                                    {li.body}
                                </p>
                                {!!li.body2 === true
                                  ? <p className={'lane-font-body-2'}>
                                      {li.body2}
                                    </p>
                                  :  null
                                }
                              {!!li.body3 === true
                                ? <p className={'lane-font-body-3'}>
                                    {li.body3}
                                  </p>
                                :  null
                              }
                            </div>
                        )}
                    </div>
                </div>


                <div className={'column is-hidden-mobile'}>
                    {intLane === 5
                        ? null
                        : <Link to={`/lane/${next}`}>
                            <button className={'navSidebar'}>
                                <img src={arrow} className={'lane-arrow'}/>
                            </button>
                        </Link>
                    }
                </div>
            </div>




            <div className={'columns'} style={{zIndex:0, textAlign:'center'}}>
                <div className={'column'}>
                    {intLane === 1
                        ? null
                        :
                          <Link to={`/lane/${prev}`} className={'navButton'}>
                            <a class="button is-flex-touch">LEARN ABOUT PREVIOUS</a>
                          </Link>
                    }
                </div>
                <div className={'column'}>
                    {intLane === 5
                        ? null
                        :
                        <Link to={`/lane/${next}`} className={'navButton'}>
                          <a class="button is-flex-touch">LEARN ABOUT NEXT LANE</a>
                        </Link>
                    }
                </div>
            </div>
        </div>
    )
};

