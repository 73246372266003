import React from 'react'
import Group2 from "../assets/DashboardImages/Group2.png"
import StockBackground from "../assets/DashboardImages/StockBackground.png"
import {Lane} from "../Lanes/Lane"
import {laneLogos} from "../assets/LaneLogos/laneLogos";
import { Link } from 'react-router-dom';
import {lpConstants} from "./constants"

export const LandingPage = props => {
	const {
		collegeRoadmap,
		whyIsItUseful,
		readinessLanes,
		lane1,
		lane2,
		lane3,
		lane4,
		lane5,
		button,
		FAQ,
		q1,
		a1,
		q2,
		a2,
		a2a,
		q3,
		a3,
		a3a,
		a3b,
		q4,
		a4,
		a4a,
		q5,
		a5,
		a5a,
		terms,
		termsQ1,
		termsA1,
		termsQ5,
		termsA5A,
		termsA5B,
		termsQ2,
		termsA2,
		termsA2A,
		termsQ3,
		termsA3,
		termsA3A,
		termsA3B,
		termsQ4,
		termsA4,
		termsA4A,
		termsA4B,
		termsA4C
	} = lpConstants

	return (
		<div id={'mainPage'}>
			<div id={"collegeRoadmap"}>
				<p className={'section-title'}>{collegeRoadmap.title}</p>
				<p className={'section-body'}>{collegeRoadmap.text}</p>
			</div>

			<div id={"groupImage"}>
				<img src={Group2}/>
			</div>

			<div id={'whyIsItUseful'}>
				<div id={'textWrapper'}>
					<p className={'section-title'} style={{color: 'white', paddingTop: "200px"}}>{whyIsItUseful.title}</p>
					<p className={"section-body"} style={{color: 'white'}}>{whyIsItUseful.text}</p>
				</div>
			</div>

			<div id={"readinessLanes"}>
				<p className={'section-title'} style={{margin: "0 15px 0 15px"}}>{readinessLanes.title}</p>
				<p className={'section-body'}>
					{readinessLanes.text}
				</p>

				<div className={"columns"} style={{marginTop: "25px", marginBottom: "25px", paddingLeft: '30px', paddingRight: '30px'}}>
					<div className={'column is-one-fifth'}>
						<Link to={'/lane/1'}>
							<img className={'readiness-image'} src={laneLogos.lane1}/>
							<p>{lane1.text}</p>
							<a class="button is-flex-touch">{button.text}</a>
						</Link>
					</div>

					<div className={'column is-one-fifth'}>
						<Link to={'/lane/2'}>
							<img className={'readiness-image'} src={laneLogos.lane2}/>
							<p>{lane2.text}</p>
							<a class="button is-flex-touch">{button.text}</a>
						</Link>
					</div>

					<div className={'column is-one-fifth'}>
						<Link to={'/lane/3'}>
							<img className={'readiness-image'} src={laneLogos.lane3}/>
							<p>{lane3.text}</p>
							<a class="button is-flex-touch">{button.text}</a>
						</Link>
					</div>

					<div className={'column is-one-fifth'}>
						<Link to={'/lane/4'}>
							<img className={'readiness-image'} src={laneLogos.lane4}/>
							<p>{lane4.text}</p>
							<a class="button is-flex-touch">{button.text}</a>
						</Link>
					</div>

					<div className={'column is-one-fifth'}>
						<Link to={'/lane/5'}>
							<img className={'readiness-image'} src={laneLogos.lane5}/>
							<p>{lane5.text}</p>
							<a class="button is-flex-touch">{button.text}</a>
						</Link>
					</div>
				</div>
			</div>

			<div id={"FAQ"}>
				<p className={'section-title'}>{FAQ.text}</p>
				<p className={"question"}>{q1.text}</p>
				<p className={"answer"}>{a1.text}</p>
				<p className={"question"}>{q2.text}</p>
				<p className={"answer"}>
					{a2.text}
					<br/><br/>
					{a2a.text}
				</p>
				<p className={"question"}>{q3.text}</p>
				<p className={"answer"}>
					{a3.text}
					<br/><br/>
					{a3a.text}
					<br/><br/>
					{a3b.text}
				</p>
				<p className={"question"}>{q5.text}</p>
				<p className={"answer"}>
					{a5.text}
					<br/><br/>
					{a5a.text}
				</p>
			</div>

			<div id={"termsYouShouldKnow"}>
				<p className={'section-title'} style={{margin: "0 15px 0 15px"}}>{terms.title}</p>
				<p className={"question"}>{termsQ1.text}</p>
				<p className={"answer"}>
					{termsA1.text}
					<a href="https://www.calstate.edu/apply/getting-into-the-csu" target="_blank"> here</a> for CSU and<a href="https://admission.universityofcalifornia.edu/freshman/requirements/" target="_blank"> here</a> for UC.
				</p>
				<p className={"answer-tab"}>
					A. <strong>History</strong>: 2 years <br/>
					B. <strong>English</strong>: 4 years <br/>
					C. <strong>Mathematics</strong>: 3 years <br/>
					D. <strong>Laboratory science</strong>: 2 years <br/>
					E. <strong>Language other than English</strong>: 2 years (or the equivalent to the second level of high school instruction)<br/>
					F. <strong>Visual and performing arts</strong>: 1 year <br/>
					G. <strong>College preparatory elective</strong>: 1 year (chosen from the courses listed in A-F, or another course approved by the university)<br/>
				</p>
				<p className={"question"}>{termsQ5.text}</p>
				<p className={"answer"}>
					{termsA5A.text}
				</p>
				<p className={"answer"}>
					{termsA5B.text}
				</p>
				<p className={"question"}>{termsQ2.text}</p>
					<p className={"answer"}>
						{termsA2.text}
						<a href="https://studentaid.ed.gov/sa/fafsa" target="_blank">here</a>
						{termsA2A.text}
				</p>
				<p className={"question"}>{termsQ3.text}</p>
				<p className={"answer"}>
					<strong>California Community Colleges (CCCs)</strong>
					{termsA3.text}
				</p>
				<p className={"answer"}>
					<strong>California State Colleges (CSUs)</strong>
					{termsA3A.text}
					<a href="https://www.calstate.edu/apply/freshman/getting_into_the_csu/Pages/testing-requirements.aspx" target="_blank"> here</a>
					.
				</p>
				<p className={"answer"}>
					<strong>University of California (UC) schools</strong>
					{termsA3B.text}
				</p>
				<p className={"question"}>{termsQ4.text}</p>
					<p className={"answer"}>
					<strong>SBAC</strong>
					{termsA4.text}
					<a href="http://www.smarterbalanced.org/" target="_blank">here</a>
					.
				</p>
				<p className={"answer"}>
					The
					<strong> ACT</strong>
					{termsA4A.text}
					<a href="https://www.calstate.edu/apply/freshman/getting_into_the_csu/Pages/testing-requirements.aspx" target="_blank">here</a>
					). UC schools do not consider SAT or ACT scores in admissions or scholarship awards.


				</p>
				<p className={"answer-bottom"}>
					The
					<strong> PSAT and SAT</strong>
					{termsA4B.text}
					<a href="https://collegereadiness.collegeboard.org/" target="_blank">the College Board</a>
					{termsA4C.text}
					<a href="https://www.calstate.edu/apply/freshman/getting_into_the_csu/Pages/testing-requirements.aspx" target="_blank">here</a>
					). UC schools do not consider SAT or ACT scores in admissions or scholarship awards.
				</p>
			</div>
		</div>
	)
}

