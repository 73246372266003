export const lpConstants = {
	collegeRoadmap: {
		title: "What is the College Roadmap?",
		text: "The College Roadmap provides information you can use to succeed in school and reach your goals for college and beyond. It's a collaboration between CORE Districts and participating California school districts to improve students' readiness for college."
	},
	whyIsItUseful: {
		title: "Why is it useful?",
		text: "The College Roadmap gives you a snapshot of your current readiness for the next step in your academic career, as well as useful information about the California college system and helpful action items that you can use to support your growth and success."
	},
	readinessLanes: {
		title: "The College Readiness Lanes for High School Students",
		text: "Based on our reviews of students' data, we've calculated one of five College Readiness Lanes to help guide students through the journey of education. To determine the lanes, we look at how students with similar school records (things like grades, courses taken, test scores, and attendance) across California performed after graduating high school."
	},
	lane1: {
		text: "Students in this lane are not yet developing the skills needed to graduate high school, and need help to finish."
	},
	lane2: {
		text: "Students in this lane are almost on track to graduate high school, but could use help to graduate and be ready to succeed in college or in their career."
	},
	lane3: {
		text: "Students in this lane are on track to graduate high school and are working toward completing their A-G Requirements."
	},
	lane4: {
		text: "Students in this lane are on track to be ready to go to a four-year college."
	},
	lane5: {
		text: "Students in this lane are on track to persist into their second year at a four-year college. In turn, students who persist through year 2 are likely to go on to earn their college degree."
	},
	button: {
		text: "LEARN MORE"
	},
	FAQ: {
		text: "Frequently Asked Questions"
	},
	q1: {
		text: "What are the largest predictors of college success?"
	},
	a1: {
		text: "There are many things that help you get and stay on track to graduate high school, ready for college—and to succeed in college once you get there.  The three things that most strongly predict whether you will succeed in high school and beyond are (1) getting good grades in your classes, (2) taking challenging courses (like honors, AP, or IB classes), and (3) achieving strong scores on your SBAC, SAT, or ACT tests. Making sure you come to school every day is an important step you can take to ensure you get and stay on track."
	},
	q2: {
		text: "What does this report mean?"
	},
	a2: {
		text: "This report is intended to forecast your journey through high school, to give you some directions to your destination, and to provide you with ways to track your progress as you go. CORE Districts analyzed data from past students who had similar attendance, grades, course history, and test scores as you, and tracked their journeys throughout high school and beyond. This way, you can learn from students who have already made the journey to understand what you can do to help you reach your goals and get where you want to go."
	},
	a2a: {
		text: "This report is meant to be a projection of your current trajectory and provides guidance on how to stay on track for success in high school and beyond. The lane you are assigned in the report is not set in stone. You are in the driver’s seat, and you can determine what route you take to your desired destination. Your future is in your hands."
	},
	q3: {
		text: " How did you calculate my grade point average (GPA)?"
	},
	a3: {
		text: "Your Weighted A-G GPA is also referred to as your University of California (UC) and California State University (CSU) GPA, because it is the GPA used to determine your eligibility for UC and CSU acceptance. These calculations take the grade points that you earned divided by the grade point units that you attempted for only those courses that count towards the UC and CSU requirements (i.e. the A-G course requirements). AP and honors courses receive an additional grade point earned. This is referred to as a weighted GPA. Your weighted GPA is used in UC and CSU college applications. "
	},
	a3a: {
		text: "Your Local GPA is the GPA as it is reported by your high school, and it may not match the GPA reported in the College Roadmap. Local GPAs typically include all courses that a student takes (even ones that don’t meet the A-G requirements). Local GPAs are also usually weighted to include an extra point for honors and AP coursework, but this may not be true in all instances. Contact your school counselor to better understand how your school calculates your GPA."
	},
	a3b: {
		text: "Also, contact your counselor if you notice a big difference between your GPA and the GPA reported on your Roadmap. If you have taken courses at a community college or at a different school, it’s important for you to make sure that these courses are included in your transcripts."
	},
	q5: {
		text: "How can I change lanes?"
	},
	a5: {
		text: "Changing lanes is within your reach if you are willing to adjust how you approach your school work. To move up a lane, you will need to come to school almost every day, work harder, hand your assignments in on time, take challenging coursework, and make sure that you’re taking classes that will help you meet your A-G requirements. You will also need to become an advocate for yourself.  If you miss an assignment or get a low grade, talk to your teacher right away to see how you can make up the low grade or missed assignment."
	},
	a5a: {
		text: "Although you have to do the work, you are not alone. Your teachers, school counselors, coaches, and other adults in your life want you to succeed.  Ask for help when you are uncertain about assignments or deadlines, or if you don’t understand the material.  Students who ask questions in class and ask for help after class are often more successful than students who don’t ask questions."
	},
	terms: {
		title: "Terms You Should Know"
	},
	termsQ1: {
		text: "A-G Requirements:"
	},
	termsA1: {
		text: "The A-G Requirements are 15 courses that students must take in high school if they want to be eligible to go to a California State University (CSU) and/or a University of California (UC) school. Students must pass every A-G course with a grade of C or better in order to meet the requirement (Pass (P) or Credit (CR) grades earned in spring, summer, and fall 2020, as well as spring and summer 2021, meet A-G requirements for UC). The A-G requirements are designed to help prepare students for success in college. The requirements are listed below, and you can find more information about A-G"
	},
	termsQ5: {
		text: "COVID Note:"
	},
	termsA5A: {
		text: "CSU: The CSU has temporarily suspended the letter grade requirements for “a-g” courses completed in the winter, spring, summer and fall 2020 terms and winter, spring, and summer 2021 terms. This means that for Fall 2022 and future admission cycles, for courses you took in high school during these terms, Pass and Credit grades will satisfy completion of “a-g” requirements."
	},
	termsA5B: {
			text: "UC: Pass (P) or Credit (CR) grades earned in spring, summer, and fall 2020, as well as spring and summer 2021, will meet A-G requirements for any student who was enrolled in high school during the 2019-20 and 2020-21 academic years. This includes students currently enrolled in 9th, 10th, 11th, and 12th grades."
	},
	termsQ2: {
		text: "FAFSA:"
	},
	termsA2: {
		text: "FAFSA stands for the Free Application for Federal Student Aid. Filling out the FAFSA is an annual process for students who are seeking financial aid (meaning federal grant aid or loans) to help pay for college. Grants are help paying for college that you don’t need to pay back, and loans are help paying for college that you do need to pay back. If you want financial assistance to attend college, colleges will require that you fill out the FAFSA. Students who complete the FAFSA in the fall or winter of their senior year of high school are more likely to enroll in college the fall after high school graduation, and students from low-income backgrounds are even more likely to enroll in college if they have completed the FAFSA. As the name suggests, the application is free and the application form takes less than an hour to complete once you have gathered the necessary information. Click "
	},
	termsA2A: {
		text: " for more information and to complete the FAFSA."
	},
	termsQ3: {
		text: "The California College System:"
	},
	termsA3: {
		text: " offer a wide range of two-year educational programs that are suitable for students with a variety of career goals and life situations. There are 114 different campuses to choose from across the state. A CCC may be a good option for you if you are interested in lower tuition, you want more flexibility to work while going to college, or you would like to prepare to transfer to a four-year college. Many CCCs have transfer agreements offering guaranteed or priority admission to schools within the CSU or UC System."
	},
	termsA3A: {
		text: " provide high-quality public education at a far lower cost than the average public university. With 23 campuses across California, CSUs produce the state’s highest number of bachelor’s degrees and drive California’s economy in agriculture, information technology, business, hospitality, life sciences, healthcare, public administration, education, media, and entertainment. Approximately 80% of students at CSUs receive some type of financial aid. In addition to your high school diploma, you must have completed your A-G requirements and must have earned at least a 2.0 weighted A-G grade point average (GPA) in order to be eligible to attend a CSU. SAT or ACT test scores are no longer required, though can still be submitted. Find out more information about CSU test requirements"
	},
	termsA3B: {
		text: " are the world's leading public research university system. There are 10 UC campuses, 5 medical centers, 3 national labs, and more than 150 different majors for undergraduate and graduate studies. The UC system includes more than 238,000 students and more than 190,000 faculty and staff, with more than 1.7 million alumni living and working around the world. UC schools offer four-year undergraduate degrees and opportunities for graduate education. Although admission is selective, more than 40% of UC students come from low-income backgrounds. UC's faculty are the drivers behind innovations in biotechnology, computer science, art and architecture—and they bring that knowledge and expertise directly to the classroom. To be eligible for a UC, you must have a high school diploma and earn a weighted A-G grade point average (GPA) of 3.0 or better—with no grade lower than a C. UC schools do not consider SAT or ACT scores in admissions or scholarship awards."
	},
	termsQ4: {
		text: "Assessments:"
	},
	termsA4: {
		text: " stands for the Smarter Balanced Assessment Consortium, which provides a test called the SBAC summative assessment. These summative assessments provide information about students’ progress towards college and career readiness. There are two subjects of the test: English language arts or literacy, which includes a writing portion in every grade, and math, which asks students to solve multi-step, real-world problems. These tests are administered in the spring for students in grades 3 through 8 and grade 11. It consists of two parts: a computer adaptive test and a performance task. The summative assessments describe both student achievement (how much students know at the end of the year) and student growth (how much students have improved since the previous year). To learn more about the SBAC, click "
	},
	termsA4A: {
		text: " is a college admissions test that measures what you learn in high school and helps determine your academic readiness for college. There are 7 different times every year when students can take the test. The ACT has four parts: English, math, reading, and science. There is also an optional writing test. Scores range from 1 to 36, and your score is an average across the four tests (the optional writing test ranges from 2 to 12). Some colleges require that you take either the ACT or SAT for admission. CSUs no longer have SAT or ACT test requirements as of Fall 2021 (find out more information about CSU test requirements "
	},
	termsA4B: {
		text: " are tests developed by "
	},
	termsA4C: {
		text: ". The Practice SAT (PSAT) is typically taken in 10th grade. It is a practice test meant to give students a sense of the testing environment for the SAT, help students understand where their strengths lie, and help students discover what areas they need to improve. The SAT is a college admissions test that has three parts: a reading test, a writing and language test, and a math test. There is also an optional essay. Scores range from 400 to 1600, and your score is added up across tests (the optional essay is scored from 2 to 8 on three different criteria). Some colleges require that you take either the ACT or SAT for admission. CSUs no longer have SAT or ACT test requirements as of Fall 2021 (find out more information about CSU test requirements "
	}
}
