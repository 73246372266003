import lane1 from './lane1.png'
import lane2 from './lane2.png'
import lane3 from './lane3.png'
import lane4 from './lane4.png'
import lane5 from './lane5.png'

export const laneLogos = {
    lane1,
    lane2,
    lane3,
    lane4,
    lane5,
}