import {laneLogos} from '../assets/LaneLogos/laneLogos'


export const getLaneInfo = (lane) => {
	const {
		lane1,
		lane2,
		lane3,
		lane4,
		lane5
	} = laneLogos
	switch (lane) {
		case 1:
			return {
				logo: lane1,
				title: "You’re Not Yet On Track to Graduate High School",
				subtitle: "Students in this lane are not yet developing the skills needed to graduate high school, and need help to finish.",
				list: [
					{
						body: "It looks like you could use some help developing the skills needed to be ready to graduate high school, and succeed afterward. But don’t worry—it’s not too late to get on track!"
					},
					{
						body: "It may help motivate you to know that your chances of getting a rewarding job increase dramatically when you earn a high school diploma. Research shows that people who have graduated from high school find it easier to get a job, get promotions, and make more money as a result, compared to other students who do not finish high school."
					},
					{
						body: "See your Roadmap for specific suggestions of simple things you can do to make progress right now."
					},
					{
						body: "And remember—you’re not alone. Your parents, teachers, and counselors are here to help you, so you can achieve your goals in both school and life."
					},
					{
						title: "What about changing lanes?",
						body3: "If your goal is to graduate high school, then you should talk to your counselor about how to make up credit for any courses where you previously received a D or F. Make sure to turn in all of your assignments on time and find out if there are opportunities for extra credit. Make time during and after school to complete your assignments, and turn them in even if they aren’t complete—getting a lower grade is better than getting a zero. And if you have fallen behind in class (or if you’re confused about your assignments), talk to your teachers! They want to help you succeed."
					}
				]
			}
		case 2:
			return {
				logo: lane2,
				title: "You’re Almost On Track to Graduate High School",
				subtitle: "Students in this lane are almost on track to graduate high school, and can be working toward completing their graduation requirements. They could use help to graduate, and be ready to succeed in college or in their career.",
				list: [
					{
						body: "You are almost on track to graduate high school, but not quite ready to walk across the stage. While that’s definitely a step in the right direction, it will take focused effort to complete your graduation requirements. You’re on the right track, but you have more work to do."
					},
					{
						body: "Graduating is really just the first step in your journey. Now is a good time to think about what success after graduation looks like for you. Maybe you’ll be the first in your family to graduate. Maybe you want to treat graduation as a stepping stone to a two- or four-year college. No matter what path you choose, you’ll need to start thinking about what comes next—and how to get there. If your goal is to enroll in a four-year college, be sure to look at your A-G course history Roadmap and make a plan for completing your A-G course requirements. Community College is also a great option, and all students are accepted at California Community Colleges."
					},
					{
						body: "Research shows that high school graduates who pursue at least some education after high school (such as at a two-year college or four-year college) earn more money and advance further in their careers than those who stop after graduating high school. Technical schools offer programs with certifications that will prepare you for a good career that can be aligned to your skills and interests. Take a look at your personal Roadmap for some specific suggestions of things you can do to help you change lanes based on the destination you choose."
					},
					{
						body: "And remember, you’re not alone—your family, teachers, and counselors are here to advise you. Just ask!"
					},
					{
						title: "What about changing lanes?",
						body3: "You’re on track to graduate from high school, but you should focus on completing your A-G requirements so you can be ready to attend a two- or four-year college. This will require passing  two years of lab science, three years of math, and taking four years of English that qualify for A-G status. This might seem like a lot, but if you stay focused on your goal, it will be easier to get there. Don’t be afraid to ask for help!"
					}
				]
			}
		case 3:
			return {
				logo: lane3,
				title: "You Could Be Ready For College",
				subtitle: "Students in this lane are on track to graduate high school and are working toward completing their A-G Requirements.",
				list: [
					{
						body: "You’re on track to graduate high school, but not quite ready yet to thrive in college. You might not realize how close you are to being ready for a four-year degree or a robust professional life, but in order to get there, you’ll need to focus on taking challenging classes to finish your A-G requirements and applying yourself to succeed in those classes. You’ve started to set yourself up for success, but you have more work to do. That means you have a choice between these options:"
					},
					{
						body: "1. You can shift gears and drive hard towards completing your A-G requirements, helping you make the leap to four-year college readiness. "
					},
					{
						body2: "2. You can choose to stay in your lane to prepare for community college. In community college, you can work hard to get good grades for core credits that will help you transfer to a four-year college, or you can train for a technical certificate that will prepare you for a job in healthcare, biotech, agriculture, or other fields you are interested in."
					},
					{
						body2: "3. You could find a job after high school and gain experience in the real world, but without additional school, your job options might be limited."
					},
					{
						body: "As you consider your future, remember this: the benefits to moving into a faster lane are very real. Research shows that students who enroll in some college earn more money than students who don’t enroll in any school after high school, and students who earn their college degree are much more likely to find (and keep!) jobs they are interested in."
					},
					{
						title: "What about changing lanes?",
						body3: "You’re almost ready for a two- or four-year college. To reach your destination, you should take challenging courses and do your best to get B’s or better in all of your classes. Make sure you’ve got a solid plan to complete all 15 A-G courses before you complete your senior year. This will require passing two years of lab science, three years of math, and four years of English that qualify for A-G status. This might seem like a lot, but it’s within your reach if you focus. Don’t be afraid to ask for help, especially if assignments are unclear or you’re feeling a bit lost. Your teacher will be glad you asked!"
					}
				]
			}
		case 4:
			return {
				logo: lane4,
				title: "You’re On Track For College",
				subtitle: "Students in this lane are on track to be ready to go to a four-year college.",
				list: [
					{
						body: "If you keep going with your academic accomplishments, you should be able to get into a four-year college. While that’s great news, that doesn’t mean you can coast towards the finish line—it’s time to keep your eyes on the prize and work hard to achieve your goals."
					},
					{
						body: "Although you are likely to be ready for college when you graduate high school, you will need to focus on doing your best work to get into the best school you can, and to be confident in the fast lane once you get there. You should take challenging courses, including AP or IB classes or college-level classes at your local community college, and do your best to complete assignments on time. See your personal Roadmap for some specific suggestions of how to improve your chances of success once you get to a four-year college."
					},
					{
						body: "And as always, lean on your family, teachers, and counselors for support and advice. They helped you get this far, and you can count on them to help motivate you to finish strong!"
					},
					{
						title: "What about changing lanes?",
						body3: "You’re on track to complete your A-G requirements. Congratulations—getting into a four-year school is within your reach! Though you’ve made it this far, being prepared to succeed throughout college will require a little extra effort. By taking more rigorous courses such as AP, Honors, and IB classes, you can both boost your GPA and be ready for the challenge of college-level work."
					}
				]
			}
		case 5:
			return {
				logo: lane5,
				title: "You’re On Track For College Success",
				subtitle: "Students in this lane are on track to persist into their second year at a four-year college. In turn, students who persist through year 2 are likely to go on to earn their college degree.",
				list: [
					{
						body: "If you keep going with your academic accomplishments, you should be able to go far in school and in life. You’ve developed the skills and habits you need to not only get into a four-year college, but to thrive once you’re there. But this is not an excuse to coast to the finish line! To succeed, you will need to keep your foot on the gas as you speed toward your destination."
					},
					{
						body: "You’re very likely to be prepared to earn a college degree. Students in this lane have learned that it takes a lot of dedication and hard work to achieve their dreams, and they are prepared to work hard to get what they want in life."
					},
					{
						body: "Students in this lane are taking challenging courses and going the extra mile by taking four  years of math and specialized courses in areas that interest them, such as additional years of  foreign language, AP/IB classes in the junior and senior years of school, or college-level classes at a local college. They show up for school consistently and participate in extracurricular activities to develop their interests outside of the classroom."
					},
					{
						body: "These students know that even though they are responsible for doing the work, they’re not alone. They are developing strong relationships with friends, family, and teachers who they can lean on for guidance and moral support for the road to college and beyond. They ask questions to make sure they clearly understand what is expected of them, they make a plan to succeed, and they do what it takes to follow through with that plan."
					}
				]
			}
		default:
			return {
				logo: lane1,
				title: "This is not a lane",
				list: [
					{
						title: "This is not a lane.",
						body: "This is not a lane."
					},
					{
						title: "What about changing lanes?",
						body: "This is not a lane."
					}
				]
			}
	}
}
